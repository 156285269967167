<template>
  <div class="background-wrapper">
    <div class="left-wrapper">
      <div class="telescope"></div>
      <div class="coding-laptop"></div>
    </div>
    <div class="astronaut"></div>
  </div>
</template>

<script>
export default {
  name: "BackgroundComponent",
};
</script>

<style lang="scss">
@import "@/styles/BackgroundComponent.scss";
</style>
