<template>
  <footer class="footer">
    <div class="container foot-container">
      <span class="text-center"><b>© Shaga Sresthaa, 2024</b></span>
      <span class="fhead">
        <a
          href="https://www.digitalocean.com/?refcode=85f458f92f16&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"
          ><img
            src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%202.svg"
            alt="DigitalOcean Referral Badge"
            class="flogo"
        /></a>
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
<style lang="scss">
@import "@/styles/Footer.scss";
</style>
